import '../scss/app.scss';

// eslint-disable-next-line camelcase, no-undef
declare let __webpack_public_path__: string;
__webpack_public_path__ = window.mmClientSettings.cdnPath;

const supportedBrowsers = require('../supportedBrowsers');

const appElement = document.getElementById('app');

function notSupportedMsg() {
    return `<div class="browser-error-msg">
            <div class="browser-error-msg__logo">
                <img src=${window.mmClientSettings.logoPath} alt=${window.mmClientSettings.companyName} logo />
            </div>
            <h1 class="browser-error-msg__title">Sorry, this browser isn't supported.</h1>
            <p class="browser-error-msg__link"><a href="https://browsehappy.com/" rel="noopener noreferrer">Please update your browser</a></p>
            <p class="browser-error-msg__agent">
                <strong>THIS BROWSER:</strong>
                <br />
                ${navigator.userAgent}
            </p>
        </div>`;
}

if (
    !supportedBrowsers.test(navigator.userAgent) &&
    window.mmClientSettings.userAgentCheck
) {
    // if we fail unsupported browser check, write 'not supported' message/html to App container
    if (appElement) {
        appElement.innerHTML = notSupportedMsg();
    }

    if (window.TrackJS) {
        window.TrackJS.console.error(
            `UNSUPPORTED BROWSER: ${navigator.userAgent}`
        );
    }
} else {
    if (appElement) {
        appElement.innerHTML = `<div class="loading-msg-basic"><div><h2>${window.mmClientSettings.companyName} Checkout</h2><p>Loading<span>.</span><span>.</span><span>.</span></p></div></div>`;
    }
    import('./app').then(() => true);
}
